/* tslint:disable */
/* eslint-disable */
/**
 * VWN Video streaming service docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface GetImprintRequest {
    language: GetImprintLanguageEnum;
}

/**
 * 
 */
export class SiteControllerApi extends runtime.BaseAPI {

    /**
     */
    async getImprintRaw(requestParameters: GetImprintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['language'] == null) {
            throw new runtime.RequiredError(
                'language',
                'Required parameter "language" was null or undefined when calling getImprint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/site/imprint/{language}`.replace(`{${"language"}}`, encodeURIComponent(String(requestParameters['language']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getImprint(requestParameters: GetImprintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getImprintRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetImprintLanguageEnum = {
    De: 'DE',
    En: 'EN'
} as const;
export type GetImprintLanguageEnum = typeof GetImprintLanguageEnum[keyof typeof GetImprintLanguageEnum];
