/* tslint:disable */
/* eslint-disable */
/**
 * VWN Video streaming service docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmailBounceDto } from './EmailBounceDto';
import {
    EmailBounceDtoFromJSON,
    EmailBounceDtoFromJSONTyped,
    EmailBounceDtoToJSON,
    EmailBounceDtoToJSONTyped,
} from './EmailBounceDto';

/**
 * The new object.
 * @export
 * @interface ParticipantDto
 */
export interface ParticipantDto {
    /**
     * 
     * @type {number}
     * @memberof ParticipantDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantDto
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof ParticipantDto
     */
    eventId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantDto
     */
    acceptedToS?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ParticipantDto
     */
    lastLogin?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantDto
     */
    unsubscribed?: boolean;
    /**
     * 
     * @type {EmailBounceDto}
     * @memberof ParticipantDto
     */
    blockListEntry?: EmailBounceDto;
    /**
     * 
     * @type {Date}
     * @memberof ParticipantDto
     */
    reminderSent?: Date;
    /**
     * 
     * @type {string}
     * @memberof ParticipantDto
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantDto
     */
    firstname?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantDto
     */
    organizer?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ParticipantDto
     */
    eventName?: string;
}

/**
 * Check if a given object implements the ParticipantDto interface.
 */
export function instanceOfParticipantDto(value: object): value is ParticipantDto {
    return true;
}

export function ParticipantDtoFromJSON(json: any): ParticipantDto {
    return ParticipantDtoFromJSONTyped(json, false);
}

export function ParticipantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'email': json['email'] == null ? undefined : json['email'],
        'eventId': json['eventId'] == null ? undefined : json['eventId'],
        'acceptedToS': json['acceptedToS'] == null ? undefined : json['acceptedToS'],
        'lastLogin': json['lastLogin'] == null ? undefined : (new Date(json['lastLogin'])),
        'unsubscribed': json['unsubscribed'] == null ? undefined : json['unsubscribed'],
        'blockListEntry': json['blockListEntry'] == null ? undefined : EmailBounceDtoFromJSON(json['blockListEntry']),
        'reminderSent': json['reminderSent'] == null ? undefined : (new Date(json['reminderSent'])),
        'lastname': json['lastname'] == null ? undefined : json['lastname'],
        'firstname': json['firstname'] == null ? undefined : json['firstname'],
        'organizer': json['organizer'] == null ? undefined : json['organizer'],
        'eventName': json['eventName'] == null ? undefined : json['eventName'],
    };
}

export function ParticipantDtoToJSON(json: any): ParticipantDto {
    return ParticipantDtoToJSONTyped(json, false);
}

export function ParticipantDtoToJSONTyped(value?: ParticipantDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'email': value['email'],
        'eventId': value['eventId'],
        'acceptedToS': value['acceptedToS'],
        'lastLogin': value['lastLogin'] == null ? undefined : ((value['lastLogin']).toISOString()),
        'unsubscribed': value['unsubscribed'],
        'blockListEntry': EmailBounceDtoToJSON(value['blockListEntry']),
        'reminderSent': value['reminderSent'] == null ? undefined : ((value['reminderSent']).toISOString()),
        'lastname': value['lastname'],
        'firstname': value['firstname'],
        'organizer': value['organizer'],
        'eventName': value['eventName'],
    };
}

