/* tslint:disable */
/* eslint-disable */
/**
 * VWN Video streaming service docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProcessAliveResponseDto
 */
export interface ProcessAliveResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ProcessAliveResponseDto
     */
    instanceId?: string;
}

/**
 * Check if a given object implements the ProcessAliveResponseDto interface.
 */
export function instanceOfProcessAliveResponseDto(value: object): value is ProcessAliveResponseDto {
    return true;
}

export function ProcessAliveResponseDtoFromJSON(json: any): ProcessAliveResponseDto {
    return ProcessAliveResponseDtoFromJSONTyped(json, false);
}

export function ProcessAliveResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessAliveResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'instanceId': json['instanceId'] == null ? undefined : json['instanceId'],
    };
}

export function ProcessAliveResponseDtoToJSON(json: any): ProcessAliveResponseDto {
    return ProcessAliveResponseDtoToJSONTyped(json, false);
}

export function ProcessAliveResponseDtoToJSONTyped(value?: ProcessAliveResponseDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'instanceId': value['instanceId'],
    };
}

