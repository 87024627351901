/* tslint:disable */
/* eslint-disable */
/**
 * VWN Video streaming service docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The new object.
 * @export
 * @interface EventTimeDto
 */
export interface EventTimeDto {
    /**
     * 
     * @type {number}
     * @memberof EventTimeDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof EventTimeDto
     */
    start?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventTimeDto
     */
    end?: Date;
}

/**
 * Check if a given object implements the EventTimeDto interface.
 */
export function instanceOfEventTimeDto(value: object): value is EventTimeDto {
    return true;
}

export function EventTimeDtoFromJSON(json: any): EventTimeDto {
    return EventTimeDtoFromJSONTyped(json, false);
}

export function EventTimeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventTimeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'start': json['start'] == null ? undefined : (new Date(json['start'])),
        'end': json['end'] == null ? undefined : (new Date(json['end'])),
    };
}

export function EventTimeDtoToJSON(json: any): EventTimeDto {
    return EventTimeDtoToJSONTyped(json, false);
}

export function EventTimeDtoToJSONTyped(value?: EventTimeDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'start': value['start'] == null ? undefined : ((value['start']).toISOString()),
        'end': value['end'] == null ? undefined : ((value['end']).toISOString()),
    };
}

