/* tslint:disable */
/* eslint-disable */
/**
 * VWN Video streaming service docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventTimeDto } from './EventTimeDto';
import {
    EventTimeDtoFromJSON,
    EventTimeDtoFromJSONTyped,
    EventTimeDtoToJSON,
    EventTimeDtoToJSONTyped,
} from './EventTimeDto';

/**
 * 
 * @export
 * @interface EventDto
 */
export interface EventDto {
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    uriId?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    imageSrcSet?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    eventTypeHeadline?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    imageAspect?: EventDtoImageAspectEnum;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    ndaText?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    streamUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    posterUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventDto
     */
    chatEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    prerollUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventDto
     */
    prerollAutoplayEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventDto
     */
    prerollLoopEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventDto
     */
    prerollSoundEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventDto
     */
    oneWayChat?: boolean;
    /**
     * 
     * @type {Array<EventTimeDto>}
     * @memberof EventDto
     */
    eventTimes?: Array<EventTimeDto>;
}


/**
 * @export
 */
export const EventDtoImageAspectEnum = {
    A169: 'A16_9',
    A916: 'A9_16',
    A43: 'A4_3',
    A34: 'A3_4',
    A11: 'A1_1',
    A106: 'A10_6',
    A65: 'A6_5',
    A21: 'A2_1',
    A23: 'A2_3',
    A45: 'A4_5',
    A32: 'A3_2'
} as const;
export type EventDtoImageAspectEnum = typeof EventDtoImageAspectEnum[keyof typeof EventDtoImageAspectEnum];


/**
 * Check if a given object implements the EventDto interface.
 */
export function instanceOfEventDto(value: object): value is EventDto {
    return true;
}

export function EventDtoFromJSON(json: any): EventDto {
    return EventDtoFromJSONTyped(json, false);
}

export function EventDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'uriId': json['uriId'] == null ? undefined : json['uriId'],
        'description': json['description'] == null ? undefined : json['description'],
        'imageSrcSet': json['imageSrcSet'] == null ? undefined : json['imageSrcSet'],
        'eventTypeHeadline': json['eventTypeHeadline'] == null ? undefined : json['eventTypeHeadline'],
        'imageAspect': json['imageAspect'] == null ? undefined : json['imageAspect'],
        'ndaText': json['ndaText'] == null ? undefined : json['ndaText'],
        'streamUrl': json['streamUrl'] == null ? undefined : json['streamUrl'],
        'posterUrl': json['posterUrl'] == null ? undefined : json['posterUrl'],
        'chatEnabled': json['chatEnabled'] == null ? undefined : json['chatEnabled'],
        'prerollUrl': json['prerollUrl'] == null ? undefined : json['prerollUrl'],
        'prerollAutoplayEnabled': json['prerollAutoplayEnabled'] == null ? undefined : json['prerollAutoplayEnabled'],
        'prerollLoopEnabled': json['prerollLoopEnabled'] == null ? undefined : json['prerollLoopEnabled'],
        'prerollSoundEnabled': json['prerollSoundEnabled'] == null ? undefined : json['prerollSoundEnabled'],
        'oneWayChat': json['oneWayChat'] == null ? undefined : json['oneWayChat'],
        'eventTimes': json['eventTimes'] == null ? undefined : ((json['eventTimes'] as Array<any>).map(EventTimeDtoFromJSON)),
    };
}

export function EventDtoToJSON(json: any): EventDto {
    return EventDtoToJSONTyped(json, false);
}

export function EventDtoToJSONTyped(value?: EventDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'uriId': value['uriId'],
        'description': value['description'],
        'imageSrcSet': value['imageSrcSet'],
        'eventTypeHeadline': value['eventTypeHeadline'],
        'imageAspect': value['imageAspect'],
        'ndaText': value['ndaText'],
        'streamUrl': value['streamUrl'],
        'posterUrl': value['posterUrl'],
        'chatEnabled': value['chatEnabled'],
        'prerollUrl': value['prerollUrl'],
        'prerollAutoplayEnabled': value['prerollAutoplayEnabled'],
        'prerollLoopEnabled': value['prerollLoopEnabled'],
        'prerollSoundEnabled': value['prerollSoundEnabled'],
        'oneWayChat': value['oneWayChat'],
        'eventTimes': value['eventTimes'] == null ? undefined : ((value['eventTimes'] as Array<any>).map(EventTimeDtoToJSON)),
    };
}

