import log from "loglevel";
import { createContext } from "react";
import {
    Configuration,
    EventControllerApi,
    EventDto,
    EventTimeDto,
    GetImprintLanguageEnum,
    ParticipantDto,
    ProcessAliveResponseDto,
    SiteControllerApi,
    UserControllerApi,
    UserDto
} from "../openapi/index";
import * as runtime from "../openapi/runtime";
import { backendBaseUrl } from "./constants";
import { JwtUserDto, onErrorCallback } from "./types";

export const UserContext = createContext<JwtUserDto | undefined>(undefined);

class BaseBackend {
    constructor(protected readonly onError: onErrorCallback, protected readonly accessToken: (() => Promise<string>) | undefined) {
    }

    protected async wrapBackendCall<D>(callData: Promise<runtime.ApiResponse<D>>, errorDefault: D): Promise<D> {
        return callData.then((val) => {
            const raw = val.raw;
            return val.value().catch((reason): Promise<D> => {
                log.info("recieved error: %o", raw);
                if (raw.status === 404) {
                    return Promise.reject(raw);
                }
                this.onError(String(reason), "error");
                return Promise.resolve(errorDefault);
            });
        });
    }
}

export class EventBackend extends BaseBackend {
    private readonly eventApi = new EventControllerApi(new Configuration({
        basePath: backendBaseUrl,
        accessToken: this.accessToken
    }));

    public async getAllEvents(): Promise<EventDto[]> {
        return this.wrapBackendCall(this.eventApi.getEventsRaw({ ignoreAdminFilter: true }), []);
    }

    public async getAllAdminEvents(): Promise<EventDto[]> {
        return this.wrapBackendCall(this.eventApi.getEventsRaw({ ignoreAdminFilter: false }), []);
    }

    public async getEvent(id: number): Promise<EventDto> {
        return this.wrapBackendCall(this.eventApi.getOneByIdRaw({ id: id }), {});
    }

    public async getEventByUri(eventUrlId: string): Promise<EventDto> {
        return this.wrapBackendCall(this.eventApi.getOneByUriRaw({ uriId: eventUrlId }), {});
    }

    public async getMySelf(uriId: string): Promise<ParticipantDto> {
        return this.wrapBackendCall(this.eventApi.getMySelfRaw({ uriId }), {});
    }

    public async processAlive(): Promise<ProcessAliveResponseDto> {
        return this.wrapBackendCall(this.eventApi.processAliveRaw(), {});
    }

    public async acceptTerms(uriId: string): Promise<void> {
        return this.eventApi.acceptTerms({ uriId }).catch(() => {
            log.info("error while accepting terms");
        });
    }

    public async unsubscribe(code: string): Promise<void> {
        return this.eventApi.unsubscribe({ codedLink: code }).catch(reason => this.onError(reason, "error"));
    }

    public async getFutureEventTimes(uriId: string): Promise<EventTimeDto[]> {
        return this.wrapBackendCall(this.eventApi.getFutureEventTimesRaw({ uriId }), []);
    }
}

export class UserBackend extends BaseBackend {
    private readonly userApi = new UserControllerApi(new Configuration({
        basePath: backendBaseUrl,
        accessToken: this.accessToken
    }));

    public async getMe(): Promise<UserDto> {
        return this.userApi.whoAmI();
    }
}

export class SiteBackend extends BaseBackend {
    private readonly siteApi = new SiteControllerApi(new Configuration({ basePath: backendBaseUrl }));

    constructor(onError: onErrorCallback) {
        super(onError, undefined);
    }

    public async getImprint(lang: GetImprintLanguageEnum): Promise<string> {
        return this.wrapBackendCall(this.siteApi.getImprintRaw({ language: lang }), "");
    }
}
