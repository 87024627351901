interface Imageset {
    [resolution: string]: string;
}

export const isDevEnv = process.env.NODE_ENV === "development";

const createSrcMap = (prefix: string, extension?: string): Imageset => {
    const ext = extension ? extension : ".jpg";
    const base = isDevEnv ? "https://vids-dev.vwapps.run/assets/" : "/assets/";
    return Object.assign({}, ...[320, 640, 1280, 2600].map(res => ({ [res]: base + prefix + res + ext })));
};

const createVideoSrcSet = (prefix: string): string[] => {
    const base = isDevEnv ? "https://vids-dev.vwapps.run/assets/" : "/assets/";
    const fileNamesWithBreakpoints: string[] = [];
    Object.assign({}, ...[320, 640, 1280, 2600].map(res => (fileNamesWithBreakpoints.push(base + prefix + res + ".mp4"))));
    return fileNamesWithBreakpoints;
};

const createSrcSet = (imageSet: Imageset): string => {
    return Object.keys(imageSet).map(key => imageSet[key] + " " + key + "w").join(",");
};

const homeBgSrcMap = createSrcMap("stagingbg_");
export const homeBgSrcSet = createSrcSet(homeBgSrcMap);
export const homeVideoSrcSet = createVideoSrcSet("stagingbg_");
export const useHomeVideoBackground = true;

const missingEventSrcMap = createSrcMap("AdobeStock_3856999841_");
export const missingEventSrcSet = createSrcSet(missingEventSrcMap);

const expiredEventSrcMap = createSrcMap("AdobeStock_2632297772_");
export const expiredEventSrcSet = createSrcSet(expiredEventSrcMap);

const error404SrcMap = [createSrcMap("AdobeStock_2497807571_"), createSrcMap("AdobeStock_1840904032_")];
export const error404SrcSet = error404SrcMap.map(imgset => createSrcSet(imgset));

const error403SrcMap = createSrcMap("AdobeStock_3180407681_");
export const error403SrcSet = createSrcSet(error403SrcMap);
export const vwPrimaryColors = [
    "#00B0F0", "#001E50", "#6A767D", "#00437A", "#5B08A4"
];

export const replaceTagRegexp = /<\/?[^>]*>/g;

const port = window.location.port === "" ? "" : ":" + window.location.port;

export const backendBaseUrl = isDevEnv
    ? window.location.protocol + "//" + window.location.hostname + port
    : window.location.protocol + "//api." + window.location.hostname;
