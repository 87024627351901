/* tslint:disable */
/* eslint-disable */
/**
 * VWN Video streaming service docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailBounceDto
 */
export interface EmailBounceDto {
    /**
     * 
     * @type {number}
     * @memberof EmailBounceDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EmailBounceDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailBounceDto
     */
    message?: string;
    /**
     * 
     * @type {Date}
     * @memberof EmailBounceDto
     */
    created?: Date;
}

/**
 * Check if a given object implements the EmailBounceDto interface.
 */
export function instanceOfEmailBounceDto(value: object): value is EmailBounceDto {
    return true;
}

export function EmailBounceDtoFromJSON(json: any): EmailBounceDto {
    return EmailBounceDtoFromJSONTyped(json, false);
}

export function EmailBounceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailBounceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'email': json['email'] == null ? undefined : json['email'],
        'message': json['message'] == null ? undefined : json['message'],
        'created': json['created'] == null ? undefined : (new Date(json['created'])),
    };
}

export function EmailBounceDtoToJSON(json: any): EmailBounceDto {
    return EmailBounceDtoToJSONTyped(json, false);
}

export function EmailBounceDtoToJSONTyped(value?: EmailBounceDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'email': value['email'],
        'message': value['message'],
        'created': value['created'] == null ? undefined : ((value['created']).toISOString()),
    };
}

